import Vue from 'vue'
import VueRouter from 'vue-router'
import { isUserLoggedIn } from '@/auth/utils'
// eslint-disable-next-line no-unused-vars
import navMenuItems from '@/navigation/vertical'
// eslint-disable-next-line no-unused-vars
import { canNavigate } from '@/libs/acl/routeProtection'
import store from '@/store'

// lazy load
const importPage = view => () => import(/* webpackChunkName: "p-[request]" */ `@/views/${view}.vue`)

Vue.use(VueRouter)

// const tes = navMenuItems().then(result => {
//   console.log('tes', result) // "Some User token"
//   return result
// })
console.log('axio navMenuItems', store.getters['verticalMenu/getMenu'])

// eslint-disable-next-line no-unused-vars,no-shadow
const flattenArr = arr => {
  const result = []
  arr.forEach(item => {
    // eslint-disable-next-line no-unused-vars
    const { path, component, children } = item

    if (item.route !== undefined && item.route !== null && item.file_component !== undefined && item.route !== 'home') {
      result.push({
        path: `/${item.route}`,
        name: item.route,
        component: importPage(item.file_component),
        meta: {
          title: item.title,
          pageTitle: item.title
        }
      })
    }
    if (children) result.push(...flattenArr(children))
  })
  return result
}

const routeList = [
  // {
  //   path: '/',
  //   name: 'home',
  //   // component: () => import('@/views/Home.vue'),
  //   component: importPage('Home'),
  //   meta: {
  //     title: 'Home',
  //     pageTitle: 'Home'
  //     // breadcrumb: [
  //     //   {
  //     //     text: 'Home',
  //     //     active: true,
  //     //   },
  //     // ],
  //   }
  // },
  // {
  //   path: '/second-page',
  //   name: 'second-page',
  //   // component: () => import('@/views/SecondPage.vue'),
  //   component: importPage('SecondPage'),
  //   meta: {
  //     title: 'Second Page',
  //     pageTitle: 'Second Page'
  //     // breadcrumb: [
  //     //   {
  //     //     text: 'Second Page',
  //     //     active: true,
  //     //   },
  //     // ],
  //   }
  // },
  // {
  //   path: '/map',
  //   name: 'map',
  //   component: importPage('Map'),
  //   meta: {
  //     layout: 'fullbg'
  //   }
  // },
  {
    path: '/userLogin',
    name: 'userLogin',
    component: importPage('UserLogin'),
    meta: {
      layout: 'fullbg'
    }
  },
  {
    path: '/userRegister',
    name: 'userRegister',
    component: importPage('UserRegister'),
    meta: {
      layout: 'full'
    }
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: importPage('error/Error404'),
    meta: {
      layout: 'full'
    }
  },
  {
    path: '*',
    redirect: 'error-404'
  },
  ...flattenArr(store.getters['verticalMenu/getMenu'])
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: routeList
})

router.beforeEach((to, from, next) => {
  // // eslint-disable-next-line no-undef
  // if (!hasNecessaryRoute(to)) {
  //   console.log('to', to)
  // }
  // if (to.matched.some(record => record.meta.auth) && isUserLoggedIn() !== null) {

  // if (!canNavigate(to)) {
  //   if (to.name !== 'userLogin' && isUserLoggedIn() === null) {
  //     next('/userLogin')
  //     return
  //   }
  //
  //   // If logged in => not authorized
  //   next({ name: 'misc-not-authorized' })
  //   return
  // }

  // if (to.name !== 'userLogin' && isUserLoggedIn() === null) {
  //   if (to.name === 'map') {
  //     next()
  //   } else {
  //     next('/map')
  //   }
  //   return
  // }

  if (to.name !== 'userLogin' && isUserLoggedIn() === null) {
    next('/userLogin')
    return
  }

  if (isUserLoggedIn() && to.name === 'userLogin') {
    next('/')
    return
  }

  next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
